import React, { useEffect } from "react";
import { Loader } from "../../../../components/Loader/Loader";
import { useAppSelector } from "../../../../hooks/redux";
import s from "./AccountsWidget.module.css";
import { Layout, Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import info from "../../../../assets/images/personalAccount/information.svg";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import { useNavigate } from "react-router-dom";
import AccountIcon from "../../../../assets/images/personalAccount/accounts-white.svg";
import { GetAccountsListThunk } from "../../../../store/reducers/ActionCreators";
import { useAppDispatch } from "../../../../store/store";

interface DataType {
    balance: number;
    currency: string;
    number: string;
    status: string;
}

const AccountsWidget = () => {

    const dispatch = useAppDispatch()

    const {userToken} = useAppSelector(store => store.userReducer.user);

    useEffect(() => {
        dispatch(GetAccountsListThunk(userToken))
    }, [dispatch, userToken])

    const navigate = useNavigate();
    const { isLoading } = useAppSelector((store) => store.userReducer);
    const accountsList = useAppSelector((store) => store.userReducer.accountsList);
    const accounts = accountsList.filter((account: any) => account.status === "Active");

    const getType = (type: string) => {
        if (type === "Requested") {
            return "pending approval";
        }
        if (type === "Inactive") {
            return "blocked";
        }
    };

    const columns: ColumnsType<DataType> = [
        {
            title: "Number",
            dataIndex: "number",
        },
        {
            title: "Currency",
            dataIndex: "currency",
            align: "right",
        },
        {
            title: "Available balance",
            dataIndex: "availableBalance",
            align: "right",
        },
    ];

    const tableData = accounts?.map((item: DataType) => {
        return {
            key: item.number,
            number: (
                <div
                    className={
                        item.status === "Active"
                            ? s.numberWrapper
                            : `${s.numberWrapper} ${s.disabled}`
                    }
                >
                    {item.number}
                    <Popover
                        content={"Your internal account number."}
                        color={"#EEEFEF"}
                        placement={"bottom"}
                        overlayInnerStyle={{
                            fontFamily: "Inter",
                            padding: "13px 16px",
                            backgroundColor: "#EEEFEF",
                            boxShadow: "none",
                        }}
                    >
                        <img src={info} alt="" />
                    </Popover>
                </div>
            ),
            currency: (
                <p className={item.status !== "Active" ? s.disabled : ""}>
                    {item.currency}
                </p>
            ),
            availableBalance:
                item.status === "Active" ? (
                    item.balance + " " + item.currency
                ) : (
                    <p
                        className={
                            item.status === "Requested" ? s.pending : s.blocked
                        }
                    >
                        {getType(item.status)}
                    </p>
                ),
        };
    });

    return isLoading ? (
        <Loader />
    ) : (
        <>
            <div className={s.accountOverviewWrapper}>
                <div className={s.accountsTitle}>Active Accounts</div>
                <Layout>
                    <Table
                        bordered={false}
                        className={s.accountTable}
                        columns={columns}
                        dataSource={tableData}
                        pagination={false}
                        scroll={{ x: 400, y: 200 }}
                    />
                </Layout>
            </div>
            <div className={s.buttonWrapper}>
                <MyBtn
                    title={"Accounts"}
                    large
                    icon={AccountIcon}
                    onClick={() => navigate("/personal_account/accounts")}
                    type={"button"}
                />
            </div>
        </>
    );
};

export default AccountsWidget;
